html, body {
    height:100%;

}




 .startGame img {
    width: 225px;
    height: 225px;
    margin: auto;
    border-radius: 0;
    object-fit: cover;
    
 }

 .startGame-pokename img {
  width: 225px;
  height: 225px;
  margin: auto;
  border-radius: 0;
  object-fit: cover;
  
}

  .startGame {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: small;
    text-align: center;

  }

  .startGame-pokename {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: small;
    text-align: center;

  }

  
  .leaderboard{
    position: fixed;
  }
  
  
  
  .equalButton button {
    position: fixed;
    font-family: 'open-sans';
    cursor: pointer;
    margin: auto;
    top:30rem;
    left: 50%;
    height:50px;
    width:100px;
    background-color: #FF013C;
    transform: translate(-50%, -50%);
  }
  
  .equalButton button:hover{
    background-color: #bf18f1;
  }
  
  .pokeCard1 {
    
    font-family: 'open-sans';
    cursor: pointer;
    text-align: center;
    padding-top: 2vw;
    padding-bottom: 2vw;
   
  
  }

  .pokeCard-pokename {
    
    font-family: 'open-sans';
    cursor: pointer;
    text-align: center;
    padding-top: 2vw;
    padding-bottom: 2vw;
   
  
  }
  
  .pokeCard2 {

    font-family: 'open-sans';
    cursor: pointer;
    text-align: center;
  
  }

  .pokeCards {
    top:0;
  }
  
  /* Desktop PokeWeight */
  @media screen and (min-width: 768px) {

    .startGame {
      padding-top: 50px;
      font-size: large;
    }
    .pokeCards {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;  
    } 

    .startGame img {
      width: 300px;
      height: 300px;
      margin: auto;
      object-fit: cover;
      
    }

    .pokeCard1 {
      padding-right: 15vw;
    }



  }
  .startGame-pokename #skipBtn {
    padding: 3px 10px;
    
  }

  .startGame-pokename button {
    appearance: button;
    background-color: #000;
    background-image: none;
    border: 1px solid #000;
    border-radius: 4px;
    box-shadow: #fff 4px 4px 0 0,#000 4px 4px 0 1px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: ITCAvantGardeStd-Bk,Arial,sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 5px 10px 0;
    overflow: visible;
    padding: 6px 34px;
    text-align: center;
    text-transform: none;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
  }
  
  .startGame-pokename button:focus {
    text-decoration: none;
  }
  
  .startGame-pokename button:hover {
    text-decoration: none;
  }
  
  .startGame-pokename button:active {
    box-shadow: rgba(0, 0, 0, .125) 0 3px 5px inset;
    outline: 0;
  }
  
  .startGame-pokename button:not([disabled]):active {
    box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
    transform: translate(2px, 2px);
  }
  
  @media (min-width: 768px) {
    .startGame-pokename button {
      padding: 12px 50px;
    }
  }

  /* Mobile  */
 
  

  .startGame button {
    appearance: button;
    background-color: #000;
    background-image: none;
    border: 1px solid #000;
    border-radius: 4px;
    box-shadow: #fff 4px 4px 0 0,#000 4px 4px 0 1px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: ITCAvantGardeStd-Bk,Arial,sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 5px 10px 0;
    overflow: visible;
    padding: 6px 34px;
    text-align: center;
    text-transform: none;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
  }
  
  .startGame button:focus {
    text-decoration: none;
  }
  
  .startGame button:hover {
    text-decoration: none;
  }
  
  .startGame button:active {
    box-shadow: rgba(0, 0, 0, .125) 0 3px 5px inset;
    outline: 0;
  }
  
  .startGame button:not([disabled]):active {
    box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
    transform: translate(2px, 2px);
  }
  
  @media (min-width: 768px) {
    .startGame button {
      padding: 12px 50px;
    }
  }

  #leaderboard-display {
    font-size: 20px;
  }

  .toast-success-container {
    color: #000 !important;
    border-radius: 8px !important;
    background: #FFFFFF !important;
    border: 1px solid #34A853 !important;
    box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  }
  
  .toast-success-container-after {
    overflow: hidden;
    position: relative;
  }
  
  .toast-success-container-after::after{
    top: 0;
    left: 0;
    content: '';
    width: 7px;
    height: 100%;
    position: absolute;
    display: inline-block;
    background-color: #34A853;
  }

  .toast-error-container {
    color: #000 !important;
    border-radius: 8px !important;
    background: #FFFFFF !important;
    border: 1px solid red !important;
    box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  }

  .toast-error-container-after {
    overflow: hidden;
    position: relative;
  }
  
  .toast-error-container-after::after{
    top: 0;
    left: 0;
    content: '';
    width: 7px;
    height: 100%;
    position: absolute;
    display: inline-block;
    background-color: red;
  }



  .startGame {
    --toastify-color-light: #121212;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  
    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);
  
    --toastify-toast-width: 320px;
    --toastify-toast-background: rgb(255, 255, 255);
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;
  
    --toastify-text-color-light: #fff;
    --toastify-text-color-dark: #fff;
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: rgb(255, 255, 255);
  
    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;
  
  
    --toastify-color-progress-light: linear-gradient(
      to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55
    );
  
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
  }

  .circular{
    height:100px;
    width: 100px;
    position: relative;  
    border:none;
  }

  .circular .inner{
    position: absolute;
    z-index: 6;
    top: 50%;
    left: 50%;
    height: 80px;
    width: 80px;
    margin: -40px 0 0 -40px;
    background:#fff;
    border-radius: 100%;
  }

  .circular .number{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index:10;
    font-size:18px;
    font-weight:500;
    color:black;
  }

  .circular .bar{
    position: absolute;
    height: 100%;
    width: 100%;
    background: #fff;
    -webkit-border-radius: 100%;
    clip: rect(0px, 100px, 100px, 50px);
  }

  .circle .bar .progress{
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-border-radius: 100%;
    clip: rect(0px, 50px, 100px, 0px);
    background: #FF013C;
  
  }


  .circle .left .progress {
    z-index:1;
    animation: left 4s linear both;
    animation-duration: 2.5s;
    backface-visibility: hidden;
    perspective: 1000;
  }
  @keyframes left{
    100%{
      transform: rotate(180deg);
    }
  }
  .circle .right {
    transform: rotate(180deg);
    z-index:3;
   
  }
  .circle .right .progress {
  
    animation: right 4s linear both;
    animation-delay:2.5s;
    animation-duration: 2.5s;
    backface-visibility: hidden;
    perspective: 1000;
  }
  @keyframes right{
    100%{
      transform: rotate(180deg);
    }
  }

  /* Desktop Poke Name */
  @media screen and (min-width: 768px) {

    .startGame-pokename {
      padding-top: 50px;
      font-size: large;
    }
    .pokeCards {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;  
    } 

    .startGame-pokename img {
      width: 300px;
      height: 300px;
      margin: auto;
      object-fit: cover;
      
    }

    .pokeCard1 {
      padding-right: 15vw;
    }

    .poke-card-name {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;  
    } 


  }

  .card-poke-name button{
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid red;
    border-radius: 0.6em;
    color: red;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1;
    margin: 20px;
    width: 10.0em;
    height: 3.0em;
    padding: 0px;
    text-decoration: none;
    text-align:center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
  }

  .card-poke-name button:hover{
    color: #fff;
    outline: 0;
  }

  /*.card-poke-name button:focus{
    color: #fff;
    outline: 0;
  }
*/
  .card-poke-name button {
    border-color: #FF013C;
    color: #fff;
    box-shadow: 0 0 40px 40px #FF013C inset, 0 0 0 0 #FF013C;
    transition: all 150ms ease-in-out;
    
  }

  [data-reach-menu-button] {
    padding: 0.75em 1em;
    background-color: #fafafa;
    border: none;
    border: 2px solid #ccc;
    border-radius: 5px;
    margin: 5px;
  }
  [data-reach-menu-button][aria-expanded="true"] {
    border-radius: 5px 5px 0 0;
  }
  
  [data-reach-menu-list] {
    border-radius: 0 0 5px 5px;
    border: 2px solid #ccc;
    background-color: white;
    transform: translateY(-2px);
  }

  /*.card-poke-name button:hover {
    box-shadow: 0 0 10px 0 #FF013C inset, 0 0 10px 4px #FF013C;
  }*/
  