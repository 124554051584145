.primary {
    color: orange;
}


button {
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html {
 
  background: rgb(63,94,251);
  background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
}



.appName{
  position: fixed;
  top: 5;
  font-family: 'open-sans';
  float: left;
  padding: 0px 20px;
}


.topnav  {
  overflow: hidden;
  margin: -8px;
  background-color: #FF013C;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  font-family: 'open-sans';
  height:45px;

}
.logoBtn {
  right:84.73%;
}

.signedInLogoBtn {
  right:80%;
}

.signOutButton {
  left:5vw;
  top:1px;
 
}




.logoButton  {
  overflow: hidden;
  margin: -8px;
  background-color: #FF013C;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 50%; /* Full width */
  font-family: 'open-sans';

}

.topnav .signoutButton:hover {
  background: #00E6F6;
  color: black;
}



/* Style the links inside the navigation bar */
.topnav a {
  float: right;
  height: 45px;
  position: relative;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 12px 26px;
  text-decoration: none;
}

 .userNameDisplay {
  position: relative;
  right:10px;
  bottom: 5px;
}

/*main login/signin page*/



.mainPage {  
  display: flex;
  padding-top: 0px;
  flex-direction: column;
  align-items: center;
  font-family: 'open-sans';
}

.mainPage img {
  border-radius: 0;
}

input {
  padding: 12px 20px;
}

.mainPage h3{
  font-size: 30px;
}

/*desktop */
@media screen and (min-width: 768px) {
  .mainPage {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;  
  } 

  input {
    padding: 5px 14px;
  }
}




/* CSS */
.LoginButton button,
button:after {
  width: 150px;
  height: 76px;
  line-height: 78px;
  font-size: 20px;
  font-family: 'Bebas Neue', sans-serif;
  background: linear-gradient(45deg, transparent 5%, #FF013C 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  box-shadow: 6px 0px 0px #00E6F6;
  outline: transparent;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor:pointer;
}

.LoginButton button:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);
  
  content: 'QUIZZER';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
  text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
  clip-path: var(--slice-0);
}

.LoginButton button:hover:after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}

@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }
  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }
  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }
  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }
  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }
  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }
  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }
  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
  }
  80% {
    clip-path: var(--slice-5);
    transform: translate(20px, -10px);
  }
  90% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 0px);
  }
  100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  }
}

@media (min-width: 768px) {
  .LoginButton button,
  .LoginButton button:after {
    width: 200px;
    height: 86px;
    line-height: 88px;
  }
}

/*Register.js*/
.registerUser {  
  display: flex;
  padding-top: 0px;
  flex-direction: column;
  align-items: center;
  font-family: 'open-sans';
}

input {
  padding: 12px 20px;
}

.registerUser h3{
  font-size: 30px;
}

/*desktop */
@media screen and (min-width: 768px) {
  .registerUser {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;  
  } 

  input {
    padding: 5px 14px;
  }
}


/* CSS */
.RegisterButton button,
.RegisterButton button:after {
width: 150px;
height: 76px;
cursor:pointer;
line-height: 78px;
font-size: 20px;
font-family: 'Bebas Neue', sans-serif;
background: linear-gradient(45deg, transparent 5%, #FF013C 5%);
border: 0;
color: #fff;
letter-spacing: 3px;
box-shadow: 6px 0px 0px #00E6F6;
outline: transparent;
position: relative;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
}

.RegisterButton button:after {
--slice-0: inset(50% 50% 50% 50%);
--slice-1: inset(80% -6px 0 0);
--slice-2: inset(50% -6px 30% 0);
--slice-3: inset(10% -6px 85% 0);
--slice-4: inset(40% -6px 43% 0);
--slice-5: inset(80% -6px 5% 0);

content: 'QUIZZER';
display: block;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
clip-path: var(--slice-0);
}

.RegisterButton button:hover:after {
animation: 1s glitch;
animation-timing-function: steps(2, end);
}

@keyframes glitch {
0% {
  clip-path: var(--slice-1);
  transform: translate(-20px, -10px);
}
10% {
  clip-path: var(--slice-3);
  transform: translate(10px, 10px);
}
20% {
  clip-path: var(--slice-1);
  transform: translate(-10px, 10px);
}
30% {
  clip-path: var(--slice-3);
  transform: translate(0px, 5px);
}
40% {
  clip-path: var(--slice-2);
  transform: translate(-5px, 0px);
}
50% {
  clip-path: var(--slice-3);
  transform: translate(5px, 0px);
}
60% {
  clip-path: var(--slice-4);
  transform: translate(5px, 10px);
}
70% {
  clip-path: var(--slice-2);
  transform: translate(-10px, 10px);
}
80% {
  clip-path: var(--slice-5);
  transform: translate(20px, -10px);
}
90% {
  clip-path: var(--slice-1);
  transform: translate(-10px, 0px);
}
100% {
  clip-path: var(--slice-1);
  transform: translate(0);
}
}

@media (min-width: 768px) {
.RegisterButton button,
.RegisterButton button:after {
  width: 200px;
  height: 86px;
  line-height: 88px;
}
}



/* Profile Page   */
.profile {  
  width: 50%;
  margin: auto;
  padding: 200px;
  text-align: center;
  font-family: 'open-sans';
}

.card {
  box-shadow: 0 4px 8px 0 rgb(250, 249, 249);
  width: 600px;
  margin: auto;
  position: absolute;
 
  top: auto;
  text-align: center;
  background-color: #FF013C;
}



.profile img {
  float: left;
  border-radius: 50%;
  
}

/* LoginForm */

.form-inner {
  display: flex;
  padding-top: 200px;
  flex-direction: column;
  align-items: center;
  font-family: 'open-sans';
}


.form-inner input {
  margin: auto;
}


.form-inner button,
.form-inner button:after {
width: 150px;
height: 76px;
line-height: 78px;
font-size: 20px;
font-family: 'Bebas Neue', sans-serif;
background: linear-gradient(45deg, transparent 5%, #FF013C 5%);
border: 0;
color: #fff;
letter-spacing: 3px;
box-shadow: 6px 0px 0px #00E6F6;
outline: transparent;
position: relative;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
}

.form-inner button:after {
--slice-0: inset(50% 50% 50% 50%);
--slice-1: inset(80% -6px 0 0);
--slice-2: inset(50% -6px 30% 0);
--slice-3: inset(10% -6px 85% 0);
--slice-4: inset(40% -6px 43% 0);
--slice-5: inset(80% -6px 5% 0);

content: 'QUIZZER';
display: block;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
clip-path: var(--slice-0);
}

.form-inner button:hover:after {
animation: 1s glitch;
animation-timing-function: steps(2, end);
}

@keyframes glitch {
0% {
  clip-path: var(--slice-1);
  transform: translate(-20px, -10px);
}
10% {
  clip-path: var(--slice-3);
  transform: translate(10px, 10px);
}
20% {
  clip-path: var(--slice-1);
  transform: translate(-10px, 10px);
}
30% {
  clip-path: var(--slice-3);
  transform: translate(0px, 5px);
}
40% {
  clip-path: var(--slice-2);
  transform: translate(-5px, 0px);
}
50% {
  clip-path: var(--slice-3);
  transform: translate(5px, 0px);
}
60% {
  clip-path: var(--slice-4);
  transform: translate(5px, 10px);
}
70% {
  clip-path: var(--slice-2);
  transform: translate(-10px, 10px);
}
80% {
  clip-path: var(--slice-5);
  transform: translate(20px, -10px);
}
90% {
  clip-path: var(--slice-1);
  transform: translate(-10px, 0px);
}
100% {
  clip-path: var(--slice-1);
  transform: translate(0);
}
}

@media (min-width: 768px) {
.form-inner button,
.form-inner button:after {
  width: 200px;
  height: 86px;
  line-height: 88px;
}
}

/*Login Page Error Toast*/

.mainPage, .registerUser{
  --toastify-color-light: #121212;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: rgb(255, 255, 255);
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #fff;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: rgb(255, 255, 255);

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;


  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );

  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.profileInformation {
  position: relative;
}


.pokeCard1 .card{
  background-color:#ff2d55;
  cursor: pointer;
  width: 300px;
  height: 200px;
  border: none;
  text-align: center;
  margin-left: 230px;
  margin-top: 200px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.pokeCard2 .card{
  background-color:#ff2d55;
  cursor: pointer;
  width: 300px;
  height: 200px;
  border: none;
  text-align: center;
  margin-left: 800px;
  margin-top: 200px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}



:root {
  --clr-white: radial-gradient(circle, rgb(220, 63, 251) 0%, rgb(103, 70, 252) 100%);
  --clr-pink: #FF013C;
  --brd-radius: 5px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sign-up-card h1 {
  text-align: center;
  color: white;
  padding-bottom: 5px;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 500;
  background-color: #FF013C;
  font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}


.section-sign-up {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0.5rem;
}
.section-sign-up .sign-up-card {
  max-width: 320px;
  background-color: var(--clr-white);
  border-radius: var(--brd-radius);
  overflow: hidden;
  box-shadow: black 0px 3px 8px;
}

.section-sign-up .sign-up-card .view {
  position: relative;
}
.section-sign-up .sign-up-card .view:before {
  content: "";
  position: absolute;
  z-index: 1;
  height: 80px;
  width: 100%;
  background-color: var(--clr-white);
  transform: skewY(-10deg);
  bottom: -40px;
}
.section-sign-up .sign-up-card .view img {
  width: 100%;
}
.section-sign-up .sign-up-card .view .check {
  height: 60px;
  width: 60px;
  background-color: #50d2c2;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  color: #fff;
  line-height: 60px;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.section-sign-up .sign-up-card .form {
  position: relative;
  z-index: 3;
  background: rgb(63,94,251);
  background: linear-gradient(0deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);;
  padding: 1rem 1rem 1rem 1rem;
}

.section-sign-up .sign-up-card .form .form-control {
  margin-bottom: 15px;
}
.section-sign-up .sign-up-card .form label {
  display: block;
  width: 100%;
  font-size: 13px;
  color: #bababa;
}
.section-sign-up .sign-up-card .form input {
  width: 100%;
  font-size: 16px;
  margin-top: 3px;
  padding: 6px;
  transition: all 0.15s linear;
  border: 1px solid #bababa;
  border-radius: var(--brd-radius);
  background-color: #f4f8fb;
}
.section-sign-up .sign-up-card .form input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.section-sign-up .sign-up-card .form .btn-sign-up {
  width: 100%;
  display: inline-block;
  padding: 0.6rem 0.5rem;
  background-color: var(--clr-pink);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  font-family: "Lato", sans-serif;
  border: 1px solid var(--clr-pink);
  border-radius: var(--brd-radius);
  cursor: pointer;
  transition: all 0.2s linear;
  margin-top: 5px;
}
.section-sign-up .sign-up-card .form .btn-sign-up:hover {
  background-color: transparent;
  color: var(--clr-pink);
}

.section-sign-up .sign-up-card .form .more {
  display: flex;
  justify-content: space-between;
}
.section-sign-up .sign-up-card .form .more a {
  text-decoration: none;
  font-size: 11px;
  display: inline-block;
}

.section-sign-up .sign-up-card .form .more .forget-password-link {
  color: white;
}
.section-sign-up .sign-up-card .form .more .sign-in-link {
  color: white;
}

