html, body {
    height:100%;

}


.startGame {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: small;
    text-align: center;

  }

 .startGame img {
    width: 225px;
    height: 225px;
    margin: auto;
    border-radius: 0;
    object-fit: cover;
    
 }


.startGame {
    --toastify-color-light: #121212;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  
    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);
  
    --toastify-toast-width: 320px;
    --toastify-toast-background: rgb(255, 255, 255);
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;
  
    --toastify-text-color-light: #fff;
    --toastify-text-color-dark: #fff;
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: rgb(255, 255, 255);
  
    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;
  
  
    --toastify-color-progress-light: linear-gradient(
      to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55
    );
  
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
  }



@media screen and (min-width: 768px) {

    .startGame {
      padding-top: 50px;
      font-size: large;
    }
    
    .startGame img {
      width: 300px;
      height: 300px;
      margin: auto;
      object-fit: cover;
      
    }

    .game-choices img {
      width: 100px;
      height: 150px;
      margin: auto;
      object-fit:fill;
    }

    .card-map-display {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;  
    } 

    


  }

  .card-poke-name button{
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid red;
    border-radius: 0.6em;
    color: red;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1;
    margin: 20px;
    width: 10.0em;
    height: 3.0em;
    padding: 0px;
    text-decoration: none;
    text-align:center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
  }

  .card-poke-name button:hover{
    color: #fff;
    outline: 0;
  }

  /*.card-poke-name button:focus{
    color: #fff;
    outline: 0;
  }
*/
  .card-poke-name button {
    border-color: #FF013C;
    color: #fff;
    box-shadow: 0 0 40px 40px #FF013C inset, 0 0 0 0 #FF013C;
    transition: all 150ms ease-in-out;
    
  }